
import { computed, defineComponent, onMounted } from "vue";
import Navbar from "@/components/Navbar.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Investors",
  components: {
    Navbar,
  },
  setup() {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });

    const { t, locale } = useI18n();

    const language_link = computed(
      () => `/invest_${locale.value == "en-US" ? "en" : "ru"}`
    );

    return {
      t,
      locale,
      language_link,
    };
  },
});
