
import { defineComponent, onMounted } from "vue";
import Navbar from "@/components/Navbar.vue";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";

export default defineComponent({
  name: "Donate",
  components: {
    Navbar,
  },
  setup() {
    const { t } = useI18n();

    onMounted(() => {
      event("enter Donate");
    });

    return {
      t,
      event,
    };
  },
});
